<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "Outstanding Payment Receipt List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Outstanding Payment Receipt List",
      items: []
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Order No.</th>
                    <th>Customer</th>
                    <th>Total Weight</th>
                    <th>Grand Total Charge</th>
                    <th>Airline</th>
                    <th>SMU No.</th>
                    <th>Shipment Date</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>BOOK008</td>
                    <td>PT AAA Express</td>
                    <td class="text-right">106</td>
                    <td class="text-right">1.378.000</td>
                    <td>TRI-MG</td>
                    <td>000-000000008</td>
                    <td>09-FEB-2021	</td>
                    <td>
                      <b-button size="sm" variant="info">
                        <i class="ri-file-text-line"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>